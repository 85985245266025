<template>
  <div class="content">
    <div class="page">
      <h2>安防监管</h2>
      <div class="message">
        通过视频监控、物联网(消防数据、窨井盖数据、垃圾桶数据等）等系统对接初步实现初步的可视化指挥。
      </div>
      <img src="../../../../assets/image/theBasicLevel/u995.png" alt="" />
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 16%;
  box-sizing: border-box;
  min-height: calc(100vh - 387px);
  width: 100%;
  margin-bottom: 50px;
  .page {
    margin-top: 50px;
    width: 100%;
    height: 720px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 30px;
    // position: relative;
    text-align: center;
    > h2 {
      text-align-last: left;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      margin: 10px 0 30px 0;
    }
    .message {
      text-align-last: left;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6b6b6b;
      margin-bottom: 50px;
    }
  }
}
</style>